import 'virtual:uno.css';
import '@unocss/reset/normalize.css';
import '@unocss/reset/sanitize/typography.css';
import '@unocss/reset/sanitize/sanitize.css';
import '@unocss/reset/sanitize/assets.css';
import '@unocss/reset/sanitize/forms.css';

import { FileRoutes } from '@solidjs/start/router';
import { Router } from '@solidjs/router';

import Root from './root';

export default function App() {
  return <>
    <Router
      root={Root}
    >
      <FileRoutes />
    </Router>
  </>;
}
